$width : 650px;

.content-mail {
    font-family : $font-family !important;
    padding: 0px;
    margin: 0px;
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top-bar {
        background-color: $theme-color ;
        height: 10px;
        width: $width;
        margin-top: 5px;
    }
    .title-bar {
        display: flex;
        width: $width;
        >div:nth-child(1) {
            width:40%;
            margin: 15px;
            >img {
                max-width: 150px;
            }
        }
        >div:nth-child(2) {
            width:60%;
            display: flex;
            justify-content: center;
            align-items: center;
            >span {
                font-size: 28px;
                font-weight: bold;
                color: $theme-color-end
            }
        }
    }
    .divider-mail {
        width: $width;
        border-top: 2px solid $border-line-color;  
    }
    .sub-title{
        padding: 20px 10px;
        >span{
            font-size: 22px;
            font-weight: bold;
            color: $theme-color-end
        }
    }
    .body-text {
        width: $width; 
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        >span>strong {
            font-weight: bold; 
            font-size: 14px !important;
        }>div {
            display: flex;
            justify-content: center;
            .link-forgot{
                padding: 15px;
                width:250px;
                margin: 15px 0px 15px 0px;
                text-align: center;
            }
        }
    }
    .footer {
        width: $width;
        padding: 15px;
        display: flex;
        .footer-left {
            width: 60%;
            border-right: 1px solid $border-line-color;
            >div
                >span {
                    margin-right: 25px;
                }
        }
        .footer-right {
            font-size: 25px;
            display: flex;
            justify-content: center;
            >div {
                cursor: pointer;
                margin-left: 15px;
            }
            .social-face{ color:#16a6fb}
            .social-linkedin {color : #0073b1}
            .social-instagram > em {color: transparent;
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                background-clip: text;
                -webkit-background-clip: text; }
            .social-twitter {color : #1da1f2} 
            .social-youtube {color : #ff0000}   
        }
    }
}