
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');

$theme-color           : #484e55;
$theme-color-hover     : #515253;
$theme-color-end       : #313539;//E6E9ED //AAB2BD //F5F7FA //F5F7FA
$theme-color-opaque    : #808080;
$theme-medium-decrade  : #3C4146;
$theme-color-disabled  : #A9A9A9;
$border-line-color     : #EAEAEA;
$text-commom-color     : #515253;
$gray-light            : #e4eaec;
$select-open           : #f8f9fa;
$select-open-hover     : #f8f9fa;
$hover                 : rgba(33, 33, 33, 0.1);

$font-family           : 'Roboto','Open Sans', Helvetica, sans-serif;
$font-size             : 0.8rem;
$tab-font-size         : 0.8rem;
$font-h4               : 1.1rem;
$distance-elements     : 5px;
$navbar-height         : 55px;








