.text-color-theme {color: $theme-color }
.opacity65 {opacity: 0.65;}
.modal-footer {padding:0.3rem 0.75rem!important; border-top: 1px solid #cacaca !important}
.modal-footer-bottom-0 {padding-bottom: 0px !important}
.modal-footer-bottom-10 {padding-bottom: 10px !important}
.adjust-height { height: 89%; padding: 18px 20px 5px 0px}
.nav-item-permissions{min-width: 220px;width: 220px;}
.box-permissions{width: 230px; overflow: hidden auto; min-width: 230px}
.box-qrcode {border-radius: 5px; padding:15px; border:1px dashed $border-line-color; width:420px}
.content-wrapper-no-overflow { overflow: hidden !important};
.default-carder {display:flex; width:100%; margin-top: 15px; border : 1px dashed $border-line-color; border-radius: 5px; padding: 5px}
.box-radio {display : flex; 
    margin-left :15px; 
    align-items : center;
    
    input {cursor:pointer}}

@media only screen and  (max-width: 1800px) and (max-height: 700px ) {
    .adjust-height { min-height: 81%; height: 81%;padding: 25px;overflow-y:auto;}
}

@media only screen and  (max-width: 1150px) and (max-height: 700px ) {
    .adjust-height { min-height: 77%; height: 77%;padding: 25px;overflow-y:auto;}
}