

.box-alert {
    margin-bottom :5px; border:1px solid #cacaca; border-radius:5px; padding:5px; display:flex; background:#f7f8f9; cursor: pointer;
}
.box-alert:hover {
    background:#cacaca;
    box-shadow : rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.box-not-visualized{
    border:2px solid #bbf1b8;
    background:#eaffef
}

.box-not-visualized:hover {
    background:#8bf0a3
}
  .notification-box {
    z-index: 99;
    margin-top: -1px;
    text-align: center;
  }
  .notification-bell {
    animation: bell 1s 1s both infinite;
  }
  .notification-bell * {
    display: block;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 15px #fff;
  }
  .bell-top {
    width: 2px;
    height: 3px;
    border-radius: 2px 2px 0 0;
  }
  .bell-middle {
    width: 9px;
    height: 8px;
    margin-top: -1px;
    border-radius: 6.5px 6.5px 0 0;
  }
  .bell-bottom {
    position: relative;
    z-index: 0;
    width: 13px;
    height: 2px;
  }
  .bell-bottom::before,
  .bell-bottom::after {
    content: '';
    position: absolute;
    top: -4px;
  }
  .bell-bottom::before {
    left: 1px;
    border-bottom: 4px solid #fff;
    border-right: 0 solid transparent;
    border-left: 4px solid transparent;
  }
  .bell-bottom::after {
    right: 1px;
    border-bottom: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 0 solid transparent;
  }
  .bell-rad {
    width: 4px;
    height: 2px;
    margin-top: 2px;
    border-radius: 0 0 4px 4px;
    animation: rad 1s 2s both infinite;
  }
  .notification-count {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    font-size: 0.75em;
    border-radius: 5px;
    background-color: #ffc107;
    color: #fff;
    animation: zoom 3s 3s both infinite;
  }
  @keyframes bell {
    0% { transform: rotate(0); }
    10% { transform: rotate(30deg); }
    20% { transform: rotate(0); }
    80% { transform: rotate(0); }
    90% { transform: rotate(-30deg); }
    100% { transform: rotate(0); }
  }
  @keyframes rad {
    0% { transform: translateX(0); }
    10% { transform: translateX(6px); }
    20% { transform: translateX(0); }
    80% { transform: translateX(0); }
    90% { transform: translateX(-6px); }
    100% { transform: translateX(0); }
  }
  @keyframes zoom {
    0% { opacity: 0; transform: scale(0); }
    10% { opacity: 1; transform: scale(1); }
    50% { opacity: 1; }
    51% { opacity: 0; }
    100% { opacity: 0; }
  }
  @keyframes moon-moving {
    0% {
      transform: translate(-200%, 600%);
    }
    100% {
      transform: translate(800%, -200%);
    }
  }