/* ========================================================================
     Component: Page loader
 ========================================================================== */

 .page-loader { width:100%; height: 86vh;  z-index: 19999; background-color: #fff; top: 0 !important;left: 0 !important; display: flex; justify-content: center;  align-items: center;}
 @media (max-height: 768px) {
    .page-loader {height: 80vh; }
}
.heigth10vh {height: 10vh;}
.heigth50vh {height: 50vh;}
.heigth60vh {height: 60vh;}
.heigth70vh {height: 70vh;}
.heigth100vh {height: 100vh;}
.heigth100px {height: 100px;}
.heigth200px {height: 200px;}
.heigth150px {height: 150px;}
.heigth210px {height: 210px;}
.heigth230px {height: 230px;}
.heigth250px {height: 250px;}
.heigth310px {height: 310px;}
.heigth330px {height: 330px;}
.heigth350px {height: 350px;}
.heigth390px {height: 390px;}
.hidden { display: none !important;}
@include media-breakpoint-up(md) {
    .section-container .page-loader {
        top: 10px;
        left: 220px;
    }
    .aside-collapsed .section-container .page-loader {
        left: 70px;
    }
    .aside-collapsed-text .section-container .page-loader {
        left: 90px;
    }
}